import React from 'react';
import {Link} from "react-router-dom";

function Navbar() {
  return (

    <header>
      <Link to={"/"}>Andrey Leschev</Link>
      <Link to={"/about"}>About</Link>
    </header>

  )
}

export default Navbar