import React from 'react';

function Footer() {
    return (
      <footer>
            <p>© Andrey Leschev 2024</p>
      </footer>
    );
  }
  
export default Footer;
  