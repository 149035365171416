import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

 function About() {
   return (
     <div>
         <Header />
         <main className="about">
            <h2>
                Middle Product Designer specializing in user-centric innovation and product evolution.
            </h2>

            <p className="text">
                I am Dejan, a seasoned Senior Product Designer with over 10 years of experience in dynamic startup environments. 
                Throughout my career, I have led end-to-end design projects, specializing in optimizing user experiences and driving product evolution. 
                I thrive in problem-solving, prioritizing user-centric needs, and delivering high-quality designs that elevate user satisfaction.
            </p>
            <p className="text">
                In previous roles, I have spearheaded strategic initiatives that significantly enhanced product metrics and user engagement. 
                I excel in guiding comprehensive design projects, from strategic planning and user research to UI/UX design and seamless 
                collaboration across teams. With a strong foundation in methodical design thinking and usability principles, 
                I leverage diverse design tools and methodologies to achieve impactful outcomes.
            </p>
            <p className="text">
                My expertise lies in developing intuitive product user interfaces and fostering collaborative environments that integrate design, 
                engineering, and product management. I am dedicated to pushing boundaries and delivering 
                innovative solutions that align with user expectations and achieve business goals.
            </p>
        </main>
        <Footer />
     </div>
   )
 }

 export default About