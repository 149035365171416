// Home.js
import React from 'react'
import Header from './components/Header';
import Footer from './components/Footer';
import {Link} from "react-router-dom";

function Home() {
  return (
    <div>
        <Header />
        <main className="main">
            <h1>Product Designnnasdasd. Interfaces. Systems. Strategy. UX. & More</h1>
            <div className="works slide delay02">
              <a href="https://operann.ru/" target="_blank" className="works__item">
                <div className="works__item__img">
                  <img 
                    src="/operann.jpg" 
                    width={4000}
                    height={3000} 
                  />
                </div>
                <div className="works__item__title">
                  <h3>OperaNN</h3>
                  <p>Nizhny Novgorod State Academic Opera and Ballet</p>
                </div>
              </a>
              <a href="https://zilcc.ru/" target="_blank" className="works__item">
                <div className="works__item__img">
                  <img 
                    src="/zil.png"
                    width={3160}
                    height={3000} 
                  />
                </div>
                <div className="works__item__title">
                <h3>ZIL</h3>
                <p>ZIL Cultural Center (Moscow)</p>
                </div>
              </a>
              <a href="https://vintage-vine.ru/" target="_blank" className="works__item">
                <div className="works__item__img">
                  <img 
                    src="/vintage.png" 
                    width={3000}
                    height={2250} 
                  />
                </div>
                <div className="works__item__title">
                <h3>Vintage-vine</h3>
                <p>Wine library. Wine clubs and tastings</p>
                </div>
              </a>
            </div>
            <div className="about">
              <p>
                I’m Andrey, a product designer based in Russia with over 10 years of diverse experience. 
                I specialize in crafting product interfaces, 
                aligning business objectives with user needs, and overseeing comprehensive design projects. 
                My passion lies in tackling diverse challenges in the dynamic design landscape.
              </p>
              <Link to={"/about"}>About</Link>
            </div>
            <div className="contact">
              <p>Get in touch</p>
              <a href="mailto:zhukstreet@mail.ru">zhukstreet@mail.ru</a>
            </div>
        </main>
        <Footer />
    </div>
  )
}

export default Home